import { Col } from 'components/grid'
import React from 'react'

interface Props {
	title: string
	img: string
	link: string
}

const Partnerships = ({ title, img, link }: Props) => {
	return (
		<Col width="w-1/3 md:w-1/4" className="mb-10">
			{link ? (
				<a href={link} target="_blank" rel="noreferrer">
					<div className="flex h-full w-full items-center border p-6 shadow-lg focus:outline-none">
						<img src={img} title={title} alt={title} className="mx-auto max-h-36" />
					</div>
				</a>
			) : (
				<div className="flex h-full w-full items-center border p-6 shadow-lg focus:outline-none">
					<img src={img} title={title} alt={title} className="mx-auto max-h-36" />
				</div>
			)}
		</Col>
	)
}

export default Partnerships
