import React from 'react'

interface Props {
	src: string
	name: string
}

export default function PastPartnerImage({ src, name }: Props) {
	return <img src={src} alt={`${name}-logo`} className="max-w-[150px]" />
}
