import LargeForm from 'components/forms/LargeForm'
import SectionTitle from 'components/global/SectionTitle'
import { Section, Row, Col } from 'components/grid'
import Layout from 'components/Layout'
import PartnershipsCard from 'components/partnerships/partnership-card'
import { partners } from 'components/partnerships/partnerships'
import PastPartnerImage from 'components/partnerships/PastPartnerImage'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import React from 'react'

export default function Partnerships() {
	return (
		<Layout pageName="Partnerships">
			<Section>
				<Row>
					<div className="absolute top-0 left-0 h-full w-full bg-cover bg-no-repeat opacity-90" style={{ backgroundImage: 'url(/images/arc-core/books-bg.jpg)' }}>
						<div className="white-transparent-gradient-strong h-full w-full"></div>
					</div>
				</Row>
				<Row center className="relative pt-32 md:pt-72">
					<Col width="w-full" className="text-center">
						<H1 className="mb-3">Partnerships</H1>
						<P className="mx-auto max-w-3xl">
							ARC partners with a nationwide cross-section of businesses and community organizations in our effort to prepare students for success in college,
							careers, and life.
						</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle title="Featured Partners" />
				<Row center>
					{partners.current
						.sort((a, b) => {
							if (a.name > b.name) return 1
							if (a.name < b.name) return -1
							return 0
						})
						.map((p) => {
							return <PartnershipsCard key={p.id} img={p.img} title={p.name} link={p.link} />
						})}
				</Row>
			</Section>
			<Section margin="mt-20">
				<SectionTitle title="Past Partnerships" />

				<div className="flex flex-wrap items-center justify-center gap-x-6 gap-y-3 grayscale sm:gap-y-1">
					{partners.past
						.sort((a, b) => {
							if (a.name > b.name) return 1
							if (a.name < b.name) return -1
							return 0
						})
						.map((p) => {
							return <PastPartnerImage key={p.name} src={p.src} name={p.name} />
						})}
				</div>
			</Section>
			<Section margin="mt-32">
				<Row className="justify-center">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm pageName="Partnerships" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
