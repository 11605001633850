import React from 'react'
export const partners = {
	current: [
		{
			id: '1',
			name: 'Alma Flor Ada & F. Isabel Campoy',
			text: [
				<p>
					Award-winning authors and education advocates Alma Flor Ada and F. Isabel Campoy work with American Reading Company in designing Spanish programs for bilingual,
					dual language, and world language classrooms. Their understanding of language acquisition theory combined with their innovative methods for incorporating
					multicultural perspectives make them uniquely qualified to help shape our Spanish book collections. Ada and Campoy share American Reading Company’s goal of
					creating environments in which students have choice, power, fun, freedom, and a sense of belonging.
				</p>
			],
			link: 'https://www.premiocampoy-ada.com/',
			img: '/images/partnerships/flor-campoy.jpg'
		},
		{
			id: '2',
			name: 'Coalition of Schools Educationg Boys of Color (COSEBOC)',
			text: [
				<p>
					The Coalition of Schools Educating Boys of Color (COSEBOC) is the only national education organization of practitioners solely focused on promoting the
					educational success of boys and young men of color. It aims to connect, inspire, support, and strengthen school leaders dedicated to the social, emotional, and
					academic development of BIPOC students.
				</p>
			],
			link: 'http://www.coseboc.org/',
			img: '/images/partnerships/coseboc.png'
		},
		{
			id: '3',
			name: 'Council of Chief State School Officers (CCSSO)',
			text: [
				<p>
					The Council of Chief State School Officers (CCSSO) is a nationwide, nonpartisan, nonprofit membership organization representing the public officials who head
					state departments of elementary and secondary education across the nation. CCSSO works to ensure every child receives the opportunity to excel before and after
					graduation. As partners, CCSSO and ARC share expertise in areas such as education technology, assessment, research and analytics, curriculum development, and
					support for educators, while also working together to best inform state policy decision making.
				</p>
			],
			link: 'https://ccsso.org/',
			img: '/images/partnerships/ccsso.jpeg'
		},
		{
			id: '4',
			name: 'EL Education',
			text: [
				<p>
					EL Education (formerly Expeditionary Learning) is redefining student achievement in diverse communities across the country. EL Education’s research-based
					approach inspires teachers and challenges students, transforming education for more than 20 years in thousands of schools. American Reading Company made it easy
					to equip classrooms with the titles they need to implement EL Education’s ELA curriculum.
				</p>
			],
			link: 'http://eleducation.org/',
			img: '/images/partnerships/el-education.svg'
		},
		{
			id: '5',
			name: 'Literacy Design Collaborative',
			text: [
				<p>
					Partners for nearly 10 years, ARC and Literacy Design Collaborative (LDC) together deliver high-quality virtual professional learning experiences that develop
					educators’ capacity to implement rigorous standards-aligned, discipline-specific assignments and instruction from kindergarten through 12th grade. Its executive
					coaches routinely work with school- and district-level leadership teams and teachers to ensure that both adult and student learning outcomes are achieved. In
					2020, Findings from a five-year i3 grant study evaluated by UCLA’s National Center for Research on Evaluation, Standards, and Student Testing (CRESST) showed
					American Reading Company and Literacy Design Collaborative can close a two-year education gap in one school year.
				</p>
			],
			link: 'http://ldc.org/',
			img: '/images/partnerships/ldc.png'
		},
		{
			id: '6',
			name: 'PDK International/Educators Rising',
			text: [
				<p>
					PDK International focuses on cultivating student interest in the teaching profession through Educators Rising and looks to help communities build a strong
					pipeline of future teachers by connecting them with districts stakeholders, state partners, institutes of higher education, and funding sources. ARC sponsored
					PDK’s heralded Educators Rising Children’s Literature Competition, including judging and publishing winning student works.
				</p>
			],
			link: 'https://pdkintl.org/',
			img: '/images/partnerships/pdk.png'
		},
		{
			id: '7',
			name: 'StandardsWork',
			text: [
				<p>
					StandardsWork focuses on the quality and rigor of education standards and how states, districts, schools, and teachers can best implement them. The organization
					believes three core areas needing elevation include: The vital role curriculum plays in delivering content and in teaching skills; The importance of building
					deep background knowledge in students; and the “octane” that specific evidence-based instructional practices can provide.
				</p>
			],
			link: 'https://standardswork.org/',
			img: '/images/partnerships/standards-work.jpg'
		},
		{
			id: '8',
			name: 'Ametek',
			text: [],
			link: 'https://www.ametek.com/',
			img: '/images/partnerships/ametek-logo.png'
		},
		{
			id: '9',
			name: 'AZ Litho',
			text: [],
			link: 'https://azlitho.com/',
			img: '/images/partnerships/az-litho-logo.png'
		},
		{
			id: '10',
			name: 'UPS',
			text: [],
			link: 'https://ups.com/',
			img: '/images/partnerships/ups-logo.png'
		},
		{
			id: '11',
			name: 'Read By 4th',
			text: [],
			link: 'https://www.readby4th.org/',
			img: '/images/partnerships/read-by-4th-logo.jpg'
		},
		{
			id: '12',
			name: 'Louisiana Department of Education',
			text: [],
			link: 'https://www.louisianabelieves.com/',
			img: '/images/partnerships/ladoe-logo.png'
		},
		{
			id: '13',
			name: 'Springboard Collaborative',
			text: [],
			link: 'https://www.springboardcollaborative.org/',
			img: '/images/partnerships/springboard-logo.png'
		},
		{
			id: '14',
			name: 'Bridges',
			text: [],
			link: 'https://bridgestowork.org/',
			img: '/images/partnerships/bridges-logo.svg'
		}
	],
	past: [
		{ src: '/images/partnerships/abell-logo.png', name: 'abell' },
		{ src: '/images/partnerships/add-a-lingua-logo.png', name: 'add-a-lingua' },
		{ src: '/images/partnerships/african-american-childrens-book.png', name: 'african american childrens book' },
		{ src: '/images/partnerships/annie-e-casey-logo.png', name: 'annie casey' },
		{ src: '/images/partnerships/college-board-logo.png', name: 'college board' },
		{ src: '/images/partnerships/core-knowledge-logo.png', name: 'core knowlege' },
		{ src: '/images/partnerships/new-futuro-logo.jpg', name: 'new futuro' },
		{ src: '/images/partnerships/research-for-action-logo.jpg', name: 'research for action' },
		{ src: '/images/partnerships/stonewall-logo.png', name: 'stonewall' },
		{ src: '/images/partnerships/student-achievement-partners.png', name: 'student achievement partners' },
		{ src: '/images/partnerships/united-way-logo.png', name: 'united way' },
		{ src: '/images/partnerships/vermont-writing-collaborative-logo.jpg', name: 'vermont writing collaborative' },
		{ src: '/images/partnerships/widener-university-logo.png', name: 'widener university' }
	]
}
